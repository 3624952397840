import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c('h2',[_vm._v("Groups")])]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.activeItem = { nodeTypeId: 2 }}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("$add")]),_vm._v("Add ")],1)],1)],1),_c('ODataTable',{ref:"table",attrs:{"url":"ListingHierarchies?$expand=parent","filter":"nodeTypeId eq 2","multi-sort":"","headers":_vm.headers,"sort-by":['parent.name', 'name']},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c(VBtn,{attrs:{"small":"","icon":"","title":"Edit"},on:{"click":function($event){$event.stopPropagation();_vm.activeItem = _vm.$clone(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$edit")])],1),_c(VBtn,{attrs:{"small":"","icon":"","title":"Delete"},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$trash")])],1)],1)]}}],null,true)}),_c('GroupDialog',{on:{"cancel":function($event){_vm.activeItem = null},"submit":_vm.onSave},model:{value:(_vm.activeItem),callback:function ($$v) {_vm.activeItem=$$v},expression:"activeItem"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }