<template>
  <div>
    <GroupsTable />
  </div>
</template>

<script>
import GroupsTable from '../components/GroupsTable.vue'

export default {
  components: {
    GroupsTable
  }
}
</script>
