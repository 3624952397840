<template>
  <Dialog
    :title="title"
    :value="value"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
  >
    <GroupForm class="pa-4" :value="value" @input="form = $event" />
  </Dialog>
</template>

<script>
import GroupForm from '../components/GroupForm.vue'
export default {
  components: {
    GroupForm
  },
  props: {
    value: Object
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
    title() {
      if (this.value) {
        const prefix = this.value.id ? 'Edit' : 'Add'
        return `${prefix} Group`
      }
      return ''
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
}
</script>
